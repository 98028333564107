<template>
  <Header />

   <section style="background-color:#970072; padding: 10px;">
    

    <div class="container" style="text-align:center;">
      <h6 class="font-3 text-white">  49th KOGS Annual Scientific Congress  </h6>
    </div>

  </section>


  <section style="padding-top:100px; padding-bottom:100px;">
    

    <div class="container">

      <div class="row">

        <div class="col-md-6" style="background-image:url('/assets/images/people-wearing-mask-workshop-new-normal(1).jpg'); background-size: cover; min-height: 300px;"></div>

      <div class="col-md-6" style="padding-top:50px; padding-bottom:50px; background-color: #fafafa; ">

        <h1 class="font-3 color-2"> <b> About The Event </b> </h1>

        <p>
          The <b>49th KOGS Annual Scientific Congress</b> will be held from <b>17th to 21st February 2025</b> at <b>Diamonds Leisure Beach & Golf Resort, Kwale County.</b> This prestigious event brings together healthcare professionals, researchers, policymakers, and industry experts to explore innovative solutions and strategies for improving maternal health in Kenya and beyond. 
        </p>

        <p>

          Under the theme, Collaborative Partnerships for Maternal Health: Building alliances to prevent maternal mortality, share best practices, and drive systematic change through innovation, the congress will provide a platform for exchanging knowledge and fostering key partnerships.

        </p>

        <router-link class="btn btn-2" to="/packages">REGISTER <i class="bi bi-arrow-right"></i> </router-link>
        
      </div>


        
      </div>

      
      
    </div>


  </section>




  <section style="padding-bottom:100px;">
    

    <div class="container">

      <div class="row">


         <div class="col-md-6" style="padding-top:100px; padding-bottom:100px; background-color: #023066; color: #fff; ">

        <p>
          Attendees will have the opportunity to participate in a range of sessions, including keynote presentations, workshops, panel discussions, and networking events.
        </p>

          <p>
           The congress will focus on addressing maternal mortality, promoting sustainable healthcare systems, and fostering collaborations among stakeholders to drive impactful change in maternal and reproductive health care. Join us for an insightful and transformative event aimed at shaping the future of maternal health.


        </p>


         <router-link class="btn btn-2" to="/packages">REGISTER <i class="bi bi-arrow-right"></i> </router-link>
        
      </div>


       <div class="col-md-6" style="background-image:url('/assets/images/group-people-are-sitting-room-with-man-wearing-lanyard-around-his-neck_1064589-236834.jpg'); background-size: cover; min-height: 300px; "></div>


        
      </div>

     
      
    </div>


  </section>



<Footer />

</template>


<script>
  import Header from './layouts/Header'
  import Footer from './layouts/Footer'
  import Swal from 'sweetalert2'
  export default{

    name : 'home',
    components : { Header, Footer },
    data(){
      return{
      }
    }
  }

</script>