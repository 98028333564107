import { createStore } from 'vuex'

export default createStore({
  state: {
    membership : localStorage.getItem('membership'),
    title : localStorage.getItem('title'),
    first_name : localStorage.getItem('first_name'),
    middle_name : localStorage.getItem('middle_name'),
    surname : localStorage.getItem('surname'),
    email : localStorage.getItem('email'),
    phone : localStorage.getItem('phone'),
    branch : localStorage.getItem('branch'),
    country : localStorage.getItem('country'),
    postal_address : localStorage.getItem('postal_address'),
    postal_code : localStorage.getItem('postal_code'),
    kmpdc : localStorage.getItem('kmpdc'),
    specialization : localStorage.getItem('specialization'),
    payment_method : localStorage.getItem('payment_method'),
    amount : localStorage.getItem('amount'),
    currency : localStorage.getItem('currency'),
    url : 'https://conferenceserver.kogs.or.ke/'
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
