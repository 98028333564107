<template>

  <div style="text-align: center; background-color: #023066; color: #fff; padding-top: 5px; padding-bottom: 1px;">
   <h6 class="font-3"> <span id="countdown"></span> </h6>
  </div>
	
	<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <div class="container">
    <router-link to="/" class="navbar-brand">
    	<img src="/assets/images/logo.png" class="logo">
    </router-link>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mx-auto mb-2 mb-lg-0">
        
        <li class="nav-item">
          <router-link class="nav-link active" aria-current="page" to="/">Home</router-link>
        </li>


         <li class="nav-item">
          <router-link class="nav-link" aria-current="page" to="/about">About</router-link>
        </li>

         <li class="nav-item">
          <router-link class="nav-link" aria-current="page" to="/program">Program</router-link>
        </li>


         <li class="nav-item">
          <router-link class="nav-link" aria-current="page" to="/speakers">Speakers</router-link>
        </li>


         <li class="nav-item">
          <router-link class="nav-link" aria-current="page" to="/venue">Venue & Accommodation</router-link>
        </li>





         <li class="nav-item">
          <router-link class="nav-link" aria-current="page" to="/contact">Contact Us</router-link>
        </li>

       


  
      </ul>
      <div class="d-flex">
       
        <router-link to="/packages" class="btn btn-1">Register <i class="bi bi-arrow-right"></i> </router-link>
      </div>
    </div>
  </div>
</nav>

</template>


<script>
  
  export default{
    name : 'header',

    data(){
      return{
        countdown : ''
      }
    },

    methods : {

      timer(){

        // Set the date we're counting down to
var countDownDate = new Date("Feb 17, 2025 00:00:00").getTime();

// Update the count down every 1 second
var x = setInterval(function() {

  // Get today's date and time
  var now = new Date().getTime();
    
  // Find the distance between now and the count down date
  var distance = countDownDate - now;
    
  // Time calculations for days, hours, minutes and seconds
  var days = Math.floor(distance / (1000 * 60 * 60 * 24));
  var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  var seconds = Math.floor((distance % (1000 * 60)) / 1000);
    
  // Output the result in an element with id="demo"
  document.getElementById("countdown").innerHTML = days + "d " + hours + "h "
  + minutes + "m " + seconds + "s ";
  

  this.countdown = days + "d " + hours + "h " + minutes + "m " + seconds + "s ";

  console.log('hello : '+this.countdown)
    
  // If the count down is over, write some text 
  if (distance < 0) {
    clearInterval(x);
    //document.getElementById("demo").innerHTML = "EXPIRED";
  }
}, 1000);

  
      }

    },

    created(){
      window.scrollTo({ top: 0, behavior: 'smooth' });
      this.timer()
    }
  }

</script>

