<template>
  <Header />

   <section style="background-color:#970072; padding: 10px;">
    

    <div class="container" style="text-align:center;">
      <h6 class="font-3 text-white">  49th KOGS Annual Scientific Congress  </h6>
    </div>

  </section>


  <section style="padding-top:60px; padding-bottom:100px;">
    

    <div class="container">

      <div class="row">


        <div class="col-md-4" style="text-align:center; padding-top: 20px; border-right: solid 1px #ddd; ">
          
          <h1> <i class="bi bi-telephone color-2"></i></h1>
          <h6> +254 726 639621 </h6>

        </div>


          <div class="col-md-4" style="text-align:center; padding-top: 20px; border-right: solid 1px #ddd; ">
          
          <h1> <i class="bi bi-envelope color-2"></i></h1>
          <h6> conference@kogs.or.ke </h6>

        </div>


          <div class="col-md-4" style="text-align:center; padding-top: 20px;">
          
          <h1> <i class="bi bi-globe color-2"></i></h1>
          <h6> www.kogs.or.ke </h6>

        </div>

        
      </div>

      
      
    </div>


  </section>




<Footer />

</template>


<script>
  import Header from './layouts/Header'
  import Footer from './layouts/Footer'
  import Swal from 'sweetalert2'
  export default{

    name : 'home',
    components : { Header, Footer },
    data(){
      return{
      }
    }
  }

</script>